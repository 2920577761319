<template>
  <div class="h100">
    <el-card class="box-card">
      <el-row class="mb20">
        <el-button type="primary" @click="dialogVisible = true;form={};type='新增'">新增</el-button>
      </el-row>
      <el-table :data="tableData" border style="width: 100%" size="mini">
        <el-table-column prop="module" label="模型" width="180">
        </el-table-column>
        <el-table-column prop="code" label="编码" width="180">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-popconfirm
  title="重构模型后，会实时加载最新数据"
  @confirm="rebuild(scope.row)"
>
   <el-button slot="reference" type="text" class="ml10" size="small">重构模型</el-button>
</el-popconfirm>
            <el-button @click="handleEdit(scope.row)" class="ml10" type="text" size="small"
              >编辑</el-button
            >
            <el-popconfirm
  title="这是一段内容确定删除吗？"
  @confirm="handleDel(scope.row)"
>
   <el-button slot="reference" type="text" class="ml10" size="small">删除</el-button>
</el-popconfirm>
           
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 200, 300, 400]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
      <!-- </div> -->
        </el-card>
    <el-dialog
      :title="type"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="模型名称" prop="module" >
          <el-input v-model="form.module" placeholder="请输入模型名称"></el-input>
        </el-form-item> 
        <el-form-item label="模型编码" prop="code" >
          <el-input v-model="form.code" placeholder="请输入模型编码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')"
          >确 定</el-button
        >
      </span>


    </el-dialog>

  </div>
</template>

<script>
import { moduleList, delModule, moduleAddOrUp,sumbitNewStatus } from "@/api/casecondit";
export default {
  data() {
    return {
      type:"新增",
      form:{
        module:'',
        code:''
      },
      rules: {
          module: [
            { required: true, message: '请输入模型名称', trigger: 'blur' },
          ],
          code: [
            { required: true, message: '请输入模型编码', trigger: 'blur' },
          ]
          },
      page: {
        total: 0,
        currentPage: 1,
        page:1,
        size:10
      },
      dialogVisible: false,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    rebuild(row){
sumbitNewStatus(row);
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            moduleAddOrUp(this.form).then(res=>{
              if(res.code==200){
                this.dialogVisible=false;
                this.form={};
                this.getList();
              }
              this.type = "新增";
            }).catch(e=>{
              
            })
            // alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    handleEdit(row) {
      this.type = "编辑";
      this.form = row;
      this.dialogVisible=true;
    },
    handleDel(row) {
      delModule(row.id).then(res=>{
        this.getList();
      })
    },
    getList({page,size}=this.page) {
      moduleList({page,size}).then((res) => {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
        this.page.currentPage = res.data.current;
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
  },
};
</script>

<style>
</style>